/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import qs from 'qs';

import request from '@/utils/request';
import type * as Type from '@/common/interface';

// export const useTimelineData = (params: Type.TimelineReq) => {
//   const apiUrl = '/answer/api/v1/activity/timeline';
//   const { data, error, mutate } = useSWR<Type.TimelineRes, Error>(
//     `${apiUrl}?${qs.stringify(params, { skipNulls: true })}`,
//     request.instance.get,
//   );
//   return {
//     data,
//     isLoading: !data && !error,
//     error,
//     mutate,
//   };
// };

export const getTimelineData = (params: Type.TimelineReq) => {
  return request.get<Type.TimelineRes>(
    `/answer/api/v1/activity/timeline?${qs.stringify(params, {
      skipNulls: true,
    })}`,
  );
};

export const getTimelineDetail = (params: {
  new_revision_id: string;
  old_revision_id: string;
}) => {
  return request.get(
    `/answer/api/v1/activity/timeline/detail?${qs.stringify(params, {
      skipNulls: true,
    })}`,
  );
};
