import Cookies from 'js-cookie';

const DOMAIN =
  process.env.NODE_ENV === 'development' ? 'localhost' : 'zwexplorer.com';

const Storage = {
  get: (key: string): any => {
    const value = localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }
    return undefined;
  },
  set: (key: string, value: any): void => {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
      return;
    }
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key: string): void => {
    localStorage.removeItem(key);
  },
  clear: (): void => {
    localStorage.clear();
  },
  getCookie: (key: string): any => {
    const value = Cookies.get(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }
    return undefined;
  },
  setCookie: (key: string, value: any): void => {
    if (typeof value === 'string') {
      Cookies.set(key, value, { domain: DOMAIN });
      return;
    }
    Cookies.set(key, JSON.stringify(value), { domain: DOMAIN });
  },
  removeCookie: (key: string): void => {
    Cookies.remove(key, { domain: DOMAIN });
  },
  clearCookie: (): void => {
    Cookies.get().forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  },
};

export default Storage;
