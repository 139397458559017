/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { FC, memo, useState, useEffect } from 'react';
import { Navbar, Container, Nav, Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams, Link, useLocation } from 'react-router-dom';

import classnames from 'classnames';

import { userCenter, floppyNavigation } from '@/utils';
import {
  loggedUserInfoStore,
  siteInfoStore,
  brandingStore,
  loginSettingStore,
  themeSettingStore,
  sideNavStore,
} from '@/stores';
// eslint-disable-next-line import/order
import { logout, useQueryNotificationStatus } from '@/services';

import './index.scss';

// import Storage from '@/utils/storage';
// import {
//   LOGGED_TOKEN_COOKIE_KEY,
//   LOGGED_TOKEN_STORAGE_KEY,
// } from '@/common/constants';

import NavItems from './components/NavItems';

const Header: FC = () => {
  const location = useLocation();
  const [urlSearch] = useSearchParams();
  const q = urlSearch.get('q');
  const { user, clear: clearUserStore } = loggedUserInfoStore();
  const { t } = useTranslation();
  const [searchStr, setSearch] = useState('');
  const siteInfo = siteInfoStore((state) => state.siteInfo);
  const brandingInfo = brandingStore((state) => state.branding);
  const loginSetting = loginSettingStore((state) => state.login);
  const { updateReview, updateVisible } = sideNavStore();
  const { data: redDot } = useQueryNotificationStatus();
  useEffect(() => {
    updateReview({
      can_revision: Boolean(redDot?.can_revision),
      revision: Number(redDot?.revision),
    });
  }, [redDot]);
  const handleInput = (val) => {
    setSearch(val);
  };

  const [navList] = useState([
    {
      eventKey: '1',
      title: '',
      router: 'index',
      text: '首页',
    },
    {
      eventKey: '2',
      title: '',
      router: 'production/list',
      text: '产品下载',
    },
    {
      eventKey: '3',
      title: '',
      router: 'plugins/list',
      text: '插件库',
    },
    {
      eventKey: '4',
      title: '',
      router: 'drive-lib/list',
      text: '驱动封装库',
    },
    {
      eventKey: '5',
      title: '',
      href: 'https://demo.zwexplorer.com/index.jsp',
      text: '文档及Demo',
    },
    {
      eventKey: '6',
      title: '',
      href: 'http://www.zwexplorer.com/apidocs',
      text: 'ApiDocs',
    },
    {
      eventKey: '7',
      title: '',
      href: '/',
      text: '开发社区',
    },
  ]);
  const clickNav = (eventKey) => {
    const list: any = navList.find((item) => item.eventKey === eventKey);
    // 政务浏览器
    const navigationUrl: String = 'http://www.zwexplorer.com/';
    if (eventKey !== '7' && eventKey !== '6' && eventKey !== '5') {
      window.location.replace(`${navigationUrl + list.router}`);
    }
    if (eventKey === '5' || eventKey === '6') {
      window.location.replace(`${list.href}`);
    }
  };

  const handleLogout = async () => {
    await logout();
    clearUserStore();
    window.location.replace(window.location.href);
  };

  // const fetchToken = async () => {
  //   const token = Storage.get(LOGGED_TOKEN_STORAGE_KEY);
  //   const cookie = Storage.getCookie(LOGGED_TOKEN_COOKIE_KEY);
  //   if (token !== undefined && cookie === undefined) {
  //     handleLogout();
  //   }
  // };

  // 每隔一段时间轮询数据
  // useEffect(() => {
  //   const intervalId = setInterval(fetchToken, 5000); // 每隔5秒轮询一次
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    if (q && location.pathname === '/search') {
      handleInput(q);
    }
  }, [q]);

  useEffect(() => {
    const collapse = document.querySelector('#navBarContent');
    if (collapse && collapse.classList.contains('show')) {
      const toggle = document.querySelector('#navBarToggle') as HTMLElement;
      if (toggle) {
        toggle?.click();
      }
    }

    // clear search input when navigate to other page
    if (location.pathname !== '/search' && searchStr) {
      setSearch('');
    }
  }, [location.pathname]);

  let navbarStyle = 'theme-colored';
  const { theme, theme_config } = themeSettingStore((_) => _);
  if (theme_config?.[theme]?.navbar_style) {
    navbarStyle = `theme-${theme_config[theme].navbar_style}`;
  }

  return (
    <Navbar
      variant={navbarStyle === 'theme-colored' ? 'dark' : ''}
      expand="lg"
      className={classnames('sticky-top', navbarStyle)}
      id="header">
      <Container className="d-flex align-items-center">
        <Navbar.Toggle
          aria-controls="navBarContent"
          className="answer-navBar me-2"
          id="navBarToggle"
          onClick={() => {
            updateVisible();
          }}
        />

        <div className="d-flex justify-content-between align-items-center nav-grow flex-nowrap">
          <Navbar.Brand to="/" as={Link} className="lh-1 me-0 me-sm-6 p-0">
            {brandingInfo.logo ? (
              <>
                <div className="d-none d-lg-block">
                  <div className="d-flex align-items-center">
                    <img
                      className="logo me-1"
                      src={brandingInfo.logo}
                      alt={siteInfo.name}
                    />
                    <span className="ms-3 fs-5 fw-bold">{siteInfo.name}</span>
                    <div className="d-none d-xl-block">
                      <span className="divider" />
                      <img
                        className="logo2 me-0"
                        src={require('@/assets/images/logo2.png')}
                        alt={siteInfo.name}
                      />
                    </div>
                  </div>
                </div>

                <img
                  className="lg-none logo me-0"
                  src={brandingInfo.mobile_logo || brandingInfo.logo}
                  alt={siteInfo.name}
                />
              </>
            ) : (
              <span>{siteInfo.name}</span>
            )}
          </Navbar.Brand>

          {/* mobile nav */}
          <div className="d-flex lg-none align-items-center flex-lg-nowrap">
            {user?.username ? (
              <NavItems redDot={redDot} userInfo={user} logOut={handleLogout} />
            ) : (
              <>
                <Button
                  variant="link"
                  className={classnames('me-2', {
                    'link-light': navbarStyle === 'theme-colored',
                    'link-primary': navbarStyle !== 'theme-colored',
                  })}
                  onClick={() => floppyNavigation.storageLoginRedirect()}
                  href={userCenter.getLoginUrl()}>
                  {t('btns.login')}
                </Button>
                {loginSetting.allow_new_registrations && (
                  <Button
                    variant={
                      navbarStyle === 'theme-colored' ? 'light' : 'primary'
                    }
                    href={userCenter.getSignUpUrl()}>
                    {t('btns.signup')}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>

        <Navbar.Collapse id="navBarContent" className="me-auto">
          <Col
            lg={10}
            className="d-none d-lg-flex justify-content-start justify-content-sm-end">
            <Nav
              className="justify-content-end fw-bold nav-right nav-item"
              variant="underline"
              defaultActiveKey="7"
              onSelect={clickNav}>
              {navList.map((item) => {
                return (
                  <Nav.Item key={item.text}>
                    <Nav.Link
                      className={`nav-link-font ${
                        item.eventKey === '7' ? 'text-primary' : ''
                      }`}
                      eventKey={item.eventKey}
                      href={item.href}>
                      {item.text}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>

          {/* pc nav */}
          <Col
            lg={2}
            className="d-none d-lg-flex justify-content-start justify-content-sm-end ms-2">
            {user?.username ? (
              <Nav className="d-flex align-items-center flex-lg-nowrap">
                <NavItems
                  redDot={redDot}
                  userInfo={user}
                  logOut={handleLogout}
                />
                <div className="d-none d-xl-block fs-6 display_name">
                  <span className="divider" />
                  <span className="d-inline-block text-truncate span-truncate">
                    {user.display_name}
                  </span>
                </div>
              </Nav>
            ) : (
              <>
                <Button
                  variant="link"
                  className={classnames('me-2', {
                    'link-light': navbarStyle === 'theme-colored',
                    'link-primary': navbarStyle !== 'theme-colored',
                  })}
                  onClick={() => floppyNavigation.storageLoginRedirect()}
                  href={userCenter.getLoginUrl()}>
                  {t('btns.login')}
                </Button>
                {loginSetting.allow_new_registrations && (
                  <Button
                    variant={
                      navbarStyle === 'theme-colored' ? 'light' : 'primary'
                    }
                    href={userCenter.getSignUpUrl()}>
                    {t('btns.signup')}
                  </Button>
                )}
              </>
            )}
          </Col>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default memo(Header);
