/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import create from 'zustand';

import { HelmetBase, HelmetUpdate } from '@/common/interface';

import siteInfoStore from './siteInfo';

interface HelmetStore {
  items: HelmetBase;
  update: (params: HelmetUpdate) => void;
}

const makePageTitle = (title = '', subtitle = '') => {
  const { siteInfo } = siteInfoStore.getState();
  if (!subtitle) {
    subtitle = `${siteInfo.name}`;
  }
  let pageTitle = subtitle;
  if (title && title !== subtitle) {
    pageTitle = `${title}${subtitle ? ` - ${subtitle}` : ''}`;
  }
  return pageTitle;
};

const pageTags = create<HelmetStore>((set) => ({
  items: {
    pageTitle: '',
    description: '',
    keywords: '',
  },
  update: (params) => {
    const o: HelmetBase = {};
    if (params.title || params.subtitle) {
      o.pageTitle = makePageTitle(params.title, params.subtitle);
    }
    o.description =
      params.description ||
      siteInfoStore.getState().siteInfo?.description ||
      '';
    o.keywords = params.keywords || '';

    set({
      items: o,
    });
  },
}));

export default pageTags;
